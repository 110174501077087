import React from 'react';
import {StockTrendLineChart} from "./StockTrendLineChart";
import {gql, useQuery} from "@apollo/client";
import {Stock} from "../shared/Domain";


const QUERY = gql`
    query stocks {
        stocks{
            id, isin, name, showChartOnDashboard
        }
    }
`;

export const StockCharts = () => {

    const {error, loading, data} = useQuery(QUERY);

    if (error) return (<p>Error :(</p>)
    if (loading) return (<p>Loading :(</p>)

    return (
        <React.Fragment>
            {data.stocks
                .filter((os: Stock) => os.isin.length > 0 && os.showChartOnDashboard)
                .sort((left: Stock, right: Stock) => left.name.toLowerCase() > right.name.toLowerCase() ? 1 : -1)
                .map((os: Stock) => <StockTrendLineChart key={os.id} isin={os.isin}/>)}
        </React.Fragment>
    )
};
