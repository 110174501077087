import React, {useEffect} from "react";
import {Chart} from "react-google-charts";
import "./PortfolioHistoryCharts.css";
import {AzurePortfolioHistory, AzurePortfolioHistoryEntry} from "../../shared/Domain";
import {chartOptions} from "../../shared";
import {Spinner} from "../../shared/components/Spinner";
import {AzureBackend} from "../../shared/AzureBackend";

export const PortfolioHistoryCharts = () => {

    const [last24hoursData, setLast24hoursData] = React.useState(new Array<Array<any>>());
    const [last7daysData, setLast7daysData] = React.useState(new Array<Array<any>>());

    useEffect(() => {
        const mapToChartData = (data: AzurePortfolioHistory) => {
            const portfolioHistoryEntries: AzurePortfolioHistoryEntry[] = data.portfolioHistoryEntries;
            const mappedData: Array<Array<any>> = portfolioHistoryEntries.map((ph: AzurePortfolioHistoryEntry) => {
                return[new Date(ph.date), ph.portfolioValue - ph.amountInvested]
            });
            return mappedData;
        };

        const date24hrsAgo = new Date();
        date24hrsAgo.setHours(date24hrsAgo.getHours() - 24);
        const date7daysAgo = new Date();
        date7daysAgo.setDate(date7daysAgo.getDate() - 7);
        const now = new Date();
        now.setHours(now.getHours() + 1); // Bug in db: Data isn't stored as UTC time in a correct way

        const azureBackend = new AzureBackend();
        azureBackend.fetchPortfolioHistory(date24hrsAgo, now).then(data => {
            setLast24hoursData([["0", "Value"]].concat(mapToChartData(data)));
        });
        azureBackend.fetchPortfolioHistory(date7daysAgo, now).then(data => {
            setLast7daysData([["0", "Value"]].concat(mapToChartData(data)));
        });

    }, []);

    return (
        <React.Fragment>
            <Spinner isLoading={last24hoursData.length === 0}>
                <div className="Widget Chart">
                    <Chart
                        chartType="LineChart"
                        data={last24hoursData}
                        width="100%"
                        height="400px"
                        loader={<div>Loading Chart</div>}
                        options={{
                            title: "last 24 hours",
                            ...chartOptions,
                            hAxis: {
                                textStyle: {
                                    color: 'white',
                                },
                                gridlines: {
                                    color: 'transparent',
                                },
                                format: 'HH:mm',
                            }
                        }}
                    />
                </div>
            </Spinner>
            <Spinner isLoading={last7daysData.length === 0}>
                <div className="Widget Chart">
                    <Chart
                        chartType="LineChart"
                        data={last7daysData}
                        loader={<div>Loading Chart</div>}
                        width="100%"
                        height="400px"
                        options={{
                            title: "last 7 days",
                            ...chartOptions,
                        }}
                    />
                </div>
            </Spinner>
        </React.Fragment>
    );
}
