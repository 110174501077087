import React from "react";
import "./Spinner.css";

export const Spinner = ({children, isLoading}: { children: any, isLoading: boolean }) => {
    if (!isLoading) return (<div>{children}</div>);
    return (
        <div className="Widget withWidth">
            <div className="loader"/>
        </div>
    )
}
