import React from "react";
import "./ConfirmModal.css";

interface ConfirmModalProps {
    show: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    message: string;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({show, onConfirm, onCancel, message}) => {
    if (!show) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <p>{message}</p>
                <button onClick={onConfirm}>Confirm</button>
                <button onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
};