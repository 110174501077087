import React from "react";

export function Config() {

    const [user, setUser] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");

    function handleSaveButton() {
        localStorage.setItem("azure_user", user);
        localStorage.setItem("azure_password", password);
    }

    return (
        <div>
            <h1>Config</h1>
            <p>Configuration of the application</p>

            <div className="InputGroup">
                <label>User:</label>
                <input type="text" required={true}
                       onChange={event => setUser(prevState => event.target.value)}/>
            </div>
            <div className="InputGroup">
                <label>Password:</label>
                <input type="password" required={true}
                       onChange={event => setPassword(prevState => event.target.value)}/>
            </div>
            <button onClick={handleSaveButton}>Save
            </button>
        </div>
    );

}