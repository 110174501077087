import React from "react";
import {gql, useQuery} from "@apollo/client";
import {InvestmentsList} from "./shared/components/InvestmentsList";
import {StockTrendLineChart} from "./dashboard/StockTrendLineChart";
import {Investment} from "./shared/Domain";
import {v4 as uuid} from "uuid";

const QUERY_ACCOUNT = gql`
    query account($name: String!){
        account(name: $name){
            accountTransactions{
                sumBuy sumDividend sumSell sumTaxes
            }
            accountBalance{
                current
                investments {isin name price numberOfShares buyInTotal buyInPerShare transactions{id size price timestamp costs transactionType}}
            }
        }
    }
`;


export function Account(props: { name: string }) {

    const {data, loading, error} = useQuery(QUERY_ACCOUNT, {variables: {name: props.name}, pollInterval: 30000});

    const [totalSum, setTotalSum] = React.useState(0);

    React.useEffect(() => {
            if (data && data.account && data.account.accountBalance && data.account.accountBalance.investments) {
                setTotalSum((data.account.accountTransactions.sumSell + data.account.accountTransactions.sumDividend + data.account.accountBalance.current)
                    - (data.account.accountTransactions.sumBuy + data.account.accountTransactions.sumTaxes));
            }
        }
        , [data]);

    if (error) return (<p>Error :(</p>)
    if (loading) return (<p>Loading...</p>)


    return <div>
        <div className="topBuffer"></div>

        {data.account.accountBalance.investments && data.account.accountBalance.investments.length > 0 &&
            <InvestmentsList investments={data.account.accountBalance.investments}/>}
        <div>{data.account && data.account.accountBalance.investments && data.account.accountBalance.investments.length > 0 && data.account.accountBalance.investments.map((i: Investment) =>
            <StockTrendLineChart key={uuid()} isin={i.isin}/>)}</div>
    </div>;
}
