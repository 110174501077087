import React from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
    mutation executeCommand($command: String!) {
        addCommandExecution(commandExecution:{name: $command}){
            name
            executedAt
            resultState
        }
    }`;

export const Commands = () => {

    const [executeCommand, {data}] = useMutation(MUTATION);

    const executeCommandOnClick = (e: React.MouseEvent<HTMLButtonElement>, commandName: string): void => {
        e.preventDefault();
        executeCommand({variables: {command: commandName}});
    };

    return (
        <React.Fragment>
            <div>
                <button onClick={(e) => executeCommandOnClick(e, "importStockPrices")}>
                    import stock prices
                </button>
            </div>
            <div>
                <button onClick={(e) => executeCommandOnClick(e, "freezePortfolioValue")}>
                    freeze portfolio
                </button>
            </div>
            <div>
                <button onClick={(e) => executeCommandOnClick(e, "importIndexes")}>
                    import indexes from google sheet
                </button>
            </div>
            <h2>last execution</h2>
            <div>
                <div>name: {data?.addCommandExecution.name}</div>
            </div>
        </React.Fragment>
    )
};
