import React, {useEffect} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {Label, Stock, StockInput} from "./shared/Domain";
import "./StockConfiguration.css";
import {Link} from "react-router-dom";
import {LabelsOnStock} from "./LabelsOnStock";
import {SubmitHandler, useForm} from "react-hook-form";
import {v4 as uuid} from "uuid";

type StockUI = Stock & {
    showOnUi: boolean;
}

type StockForm = {
    id: string,
    isin: string,
    wkn: string,
    name: string,
    showOnDashboard: boolean
};

const QUERY_STOCKS = gql`
    query stocks {
        stocks{
            id, isin, name, showChartOnDashboard, collectPrices, wkn,
            labels {id, key, value}
        }
    }
`;

const UPDATE_STOCK = gql`
    mutation updateStock($stock: StockInput) {
        updateStock(stock: $stock){
            id,
            showChartOnDashboard,
            collectPrices,
        }
    }
`;

const SAVE_STOCK = gql`
    mutation saveStock($stock: StockInput!) {
        saveStock(stock: $stock){
            id, isin, name, showChartOnDashboard, collectPrices, wkn,
            labels {id, key, value}
        }
    }
`;

export function StockConfiguration() {

    const [formFeedback, setFormFeedback] = React.useState<String>("");
    const [stocksOnUI, setStocksOnUI] = React.useState<StockUI[]>([]);
    const [allLabels, setAllLabels] = React.useState<string[]>([]);
    const {register, handleSubmit, setValue} = useForm<StockForm>();

    const {data, loading, error} = useQuery(QUERY_STOCKS);
    useEffect(() => {
        if (data) {
            const stocksOnServer: Stock[] = [...data.stocks];
            stocksOnServer.sort((a: Stock, b: Stock) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            setStocksOnUI(stocksOnServer.map<StockUI>(s => ({...s, showOnUi: true})));
            const allLabelsFromServer = stocksOnServer.flatMap<string>(stock => stock.labels ? stock.labels.map<string>(l => `${l.key}:${l.value}`) : []);
            // @ts-ignore
            setAllLabels([...new Set(allLabelsFromServer)])
        }
    }, [data]);

    const [updateStock] = useMutation(UPDATE_STOCK);
    const [addStock] = useMutation(SAVE_STOCK);

    const [filterOnDashboard, setFilterShowOnDashboard] = React.useState<boolean>(true);
    const [filterOnCollectPrices, setFilterOnCollectPrices] = React.useState<boolean>(true);
    useEffect(() => {
        setStocksOnUI(prevStocks => prevStocks.map<StockUI>(s => {
            const showOnUI = (s.showChartOnDashboard && filterOnDashboard)
                || (s.collectPrices && filterOnCollectPrices)
                || (!filterOnDashboard && !filterOnCollectPrices);

            return {
                ...s, showOnUi: showOnUI
            };
        }));
    }, [filterOnDashboard, filterOnCollectPrices]);

    if (error) return (<p>Error :(</p>)
    if (loading) return (<p>Loading :(</p>)

    const switchShowChartOnDashboard = (stock: Stock) => {
        const stockInput: StockInput = {
            showChartOnDashboard: !stock.showChartOnDashboard,
            id: stock.id,
            isin: stock.isin,
            name: stock.name,
            collectPrices: stock.collectPrices,
            wkn: stock.wkn,

        };
        updateStock({variables: {stock: stockInput}})
            .then(() => console.log("updated successfully"))
            .catch(reason => console.error(reason));
    };

    const switchCollectPrices = (stock: Stock) => {
        const stockInput: StockInput = {
            showChartOnDashboard: stock.showChartOnDashboard,
            id: stock.id,
            isin: stock.isin,
            name: stock.name,
            wkn: stock.wkn,
            collectPrices: !stock.collectPrices,
        };
        updateStock({variables: {stock: stockInput}})
            .then(() => console.log("updated successfully"))
            .catch(reason => console.error(reason));
    };

    const onSubmit: SubmitHandler<StockForm> = stockFormValues => {
        const stockInput: StockInput = {
            id: stockFormValues.id ? stockFormValues.id : uuid(),
            showChartOnDashboard: stockFormValues.showOnDashboard,
            isin: stockFormValues.isin,
            name: stockFormValues.name,
            wkn: stockFormValues.wkn,
            collectPrices: true,
        }
        console.log(stockInput);

        addStock({
            variables: {stock: stockInput}
        }).then(() => {
            stocksOnUI.push({
                ...stockInput,
                labels: [],
                showOnUi: false,
            });
            setFormFeedback("success")
        }).catch(reason => {
            setFormFeedback(reason.toString());
        });
    }

    const filterStockOnDashboard = (showOnDashboard: boolean) => setFilterShowOnDashboard(showOnDashboard);
    const filterCollectPrices = (collectPrices: boolean) => setFilterOnCollectPrices(collectPrices);

    function setSelectedStock(os: Stock) {
        setValue("id", os.id);
        setValue("isin", os.isin);
        setValue("name", os.name);
        setValue("wkn", os.wkn);
        setValue("showOnDashboard", os.showChartOnDashboard);
    }

    return <div className="StockConfiguration">
        <form className="StockForm" onSubmit={handleSubmit(onSubmit)}>
            <input {...register("id")} hidden/>
            <label>ISIN: <input {...register("isin")}/></label>
            <label>WKN: <input {...register("wkn")}/></label>
            <label>Name: <input {...register("name")}/></label>
            <label>Show on dashboard: <input type="checkbox" {...register("showOnDashboard")}/></label>
            <input type="submit" value="save"/>
            <label>{formFeedback}</label>
        </form>
        <div className="Widget">
            <h3>Filter table</h3>
            <label>Stock on dashboard: <input type="checkbox" checked={filterOnDashboard}
                                              onChange={(e) => filterStockOnDashboard(e.target.checked)}/></label>
            <label>Collect prices: <input type="checkbox" checked={filterOnCollectPrices}
                                          onChange={(e) => filterCollectPrices(e.target.checked)}/></label>
        </div>
        <table className="Widget">
            <thead>
            <tr>
                <th>chart on dashboard</th>
                <th>Name</th>
                <th>ISIN</th>
                <th>WKN</th>
                <th>Collect prices</th>
                <th>Links</th>
                <th>Labels</th>
            </tr>
            </thead>
            <tbody>
            {stocksOnUI.filter(s => s.showOnUi).map((s: Stock) => <tr key={s.id} className="HoverRow"
                                                                      onClick={() => setSelectedStock(s)}>
                <td><input type="checkbox" checked={s.showChartOnDashboard}
                           onChange={() => switchShowChartOnDashboard(s)}/></td>
                <td>{s.name}</td>
                <td>{s.isin}</td>
                <td>{s.wkn}</td>
                <td><input type="checkbox" checked={s.collectPrices} onChange={() => switchCollectPrices(s)}/>
                </td>
                <td>
                    <a className="external-link" href={"https://www.tradegate.de/orderbuch.php?isin=" + s.isin}
                       target="_blank" rel="noopener noreferrer">
                        <span role="img" aria-label="Tradegate">🔗</span> Tradegate
                    </a>
                    <a className="external-link" href={"https://www.ls-tc.de/de/aktie/" + s.isin}
                       target="_blank" rel="noopener noreferrer">
                        <span role="img" aria-label="Lang&Schwarz">🔗</span> Lang&Schwarz
                    </a>
                    <a className="external-link"
                       href={"https://news.guidants.com/#!Ticker/Feed/?txt=" + s.isin} target="_blank"
                       rel="noopener noreferrer"><span role="img" aria-label="New Guidants">🔗</span> News
                        Guidants
                    </a>
                    <a className="external-link"
                       href={"https://www.wikifolio.com/de/de/s/" + s.isin} target="_blank"
                       rel="noopener noreferrer"><span role="img" aria-label="Wifolio">🔗</span>Wikifolio
                    </a>
                    <Link className="external-link" to={{pathname: `/stock/${s.isin}`}} target="_blank">View
                        stock</Link>
                </td>
                <td>
                    <LabelsOnStock labels={s.labels ? s.labels.map<Label>(l => ({
                        id: l.id,
                        value: l.value,
                        key: l.key
                    })) : []} allLabels={allLabels} isin={s.isin}/>
                </td>

            </tr>)}
            </tbody>
        </table>
    </div>;
}

