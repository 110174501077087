import React, {useEffect, useState} from "react";
import {AzureAccount, AzureStockDto, AzureTransaction} from "../shared/Domain";
import {AzureBackend} from "../shared/AzureBackend";
import {TransactionForm} from "./components/TransactionForm";
import {ConfirmModal} from "./components/ConfirmModal";

export const TransactionList: React.FC = () => {
    const [transactions, setTransactions] = useState<AzureTransaction[]>([]);
    const [accounts, setAccounts] = useState<AzureAccount[]>([]);
    const [stocks, setStocks] = useState<AzureStockDto[]>([]);
    const [showForm, setShowForm] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [transactionToDelete, setTransactionToDelete] = useState<AzureTransaction | null>(null);
    const [transactionToEdit, setTransactionToEdit] = useState<AzureTransaction | undefined>(undefined);

    useEffect(() => {
        const azureBackend = new AzureBackend();

        azureBackend.fetchAllTransactions()
            .then((data: AzureTransaction[]) => setTransactions(data))
            .catch((error) => console.error("Error fetching transactions:", error));

        azureBackend.fetchAllAccounts()
            .then((data: AzureAccount[]) => setAccounts(data))
            .catch((error) => console.error("Error fetching accounts:", error));

        azureBackend.fetchAllStocks()
            .then((data: AzureStockDto[]) => setStocks(data))
            .catch((error) => console.error("Error fetching stocks:", error));
    }, []);

    const handleSaveTransaction = (newTransaction: AzureTransaction) => {
        setTransactions([newTransaction, ...transactions]);
        setShowForm(false);
        setTransactionToEdit(undefined);
    };

    const handleDeleteTransaction = (transaction: AzureTransaction) => {
        setTransactionToDelete(transaction);
        setShowModal(true);
    };

    const handleCloneTransaction = (transaction: AzureTransaction) => {
        const clonedTransaction = {...transaction, id: undefined};
        const azureBackend = new AzureBackend();
        azureBackend.saveTransaction(clonedTransaction, undefined)
            .then((data: AzureTransaction) => setTransactions([data, ...transactions]))
            .catch((error) => console.error("Error cloning transaction:", error));
    };

    const handleEditTransaction = (transaction: AzureTransaction) => {
        setShowForm(true);
        setTransactionToEdit({...transaction});
    }

    const confirmDeleteTransaction = () => {
        if (transactionToDelete) {
            const azureBackend = new AzureBackend();
            azureBackend.deleteTransaction(transactionToDelete.id!)
                .then(() => {
                    setTransactions(transactions.filter(t => t.id !== transactionToDelete.id));
                    setShowModal(false);
                    setTransactionToDelete(null);
                })
                .catch((error) => console.error("Error deleting transaction:", error));
        }
    };

    const getAccountName = (accountId: string) => {
        const account = accounts.find(acc => acc.id === accountId);
        return account ? account.name : "Unknown Account";
    };

    const getStockName = (isin: string) => {
        const stock = stocks.find(stock => stock.isin === isin);
        return stock ? stock.name : "Unknown Stock";
    };

    return (
        <div>
            <button onClick={() => setShowForm(!showForm)}>
                {showForm ? "Cancel" : "Add New Transaction"}
            </button>

            {showForm && (
                <TransactionForm transaction={transactionToEdit} accounts={accounts} stocks={stocks}
                                 onSave={handleSaveTransaction}/>
            )}

            <h1>Transaction List</h1>
            <ul>
                <table>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Account</th>
                        <th>ISIN</th>
                        <th>Stock Name</th>
                        <th>Total sum</th>
                        <th>Quantity</th>
                        <th>Price (EUR/unit)</th>
                        <th>Total Costs (EUR)</th>
                        <th>Type</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {transactions
                        .sort((a, b) => b.date.getTime() - a.date.getTime())
                        .map((transaction) => (
                            <tr className="HoverRow" key={transaction.id}>
                                <td className="ColumnWithSpace">{transaction.date.toLocaleDateString('de-DE')}</td>
                                <td className="ColumnWithSpace">{getAccountName(transaction.accountId)}</td>
                                <td className="ColumnWithSpace">{transaction.isin}</td>
                                <td className="ColumnWithSpace">{getStockName(transaction.isin)}</td>
                                <td className="ColumnWithSpace">{((transaction.quantity * transaction.price - transaction.totalCosts).toFixed(2))} €</td>
                                <td className="ColumnWithSpace">{transaction.quantity}</td>
                                <td className="ColumnWithSpace">{transaction.price}</td>
                                <td className="ColumnWithSpace">{transaction.totalCosts}</td>
                                <td className="ColumnWithSpace">{transaction.type}</td>
                                <td className="ColumnWithSpace">
                                    <button onClick={() => handleEditTransaction(transaction)}>Edit</button>
                                    <button onClick={() => handleDeleteTransaction(transaction)}>Delete</button>
                                    <button onClick={() => handleCloneTransaction(transaction)}>Clone</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ul>

            <ConfirmModal
                show={showModal}
                onConfirm={confirmDeleteTransaction}
                onCancel={() => setShowModal(false)}
                message="Are you sure you want to delete this transaction?"
            />
        </div>
    );
};