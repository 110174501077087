import React, {KeyboardEvent} from "react";
import "./LabelsOnStock.css";
import {v4 as uuid} from "uuid";
import {gql, useMutation} from "@apollo/client";
import {Label} from "./shared/Domain";
import Autosuggest, {
    ChangeEvent,
    InputProps,
    SuggestionsFetchRequested,
    SuggestionsFetchRequestedParams
} from "react-autosuggest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const UPDATE_LABEL = gql`
    mutation updateLabelOnStock($labelForStock: LabelForStockInput) {
        updateLabel(labelForStock: $labelForStock){
            id, isin, name, showChartOnDashboard, collectPrices, wkn,
            labels {id, key, value}
        }
    }
`;

const DELETE_LABEL_ON_STOCK = gql`
    mutation deleteLabelOnStock($labelId: String, $isin: String) {
        deleteLabelOnStock(labelId: $labelId, isin: $isin){
            id, isin, name, showChartOnDashboard, collectPrices, wkn,
            labels {id, key, value}
        }
    }
`;

const renderSuggestion = (suggestion: string) => (
    <div>
        {suggestion}
    </div>
);
const getSuggestions = (value: string, allLabels: string[]) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : allLabels.filter(s => s.toLowerCase().indexOf(inputValue.toLowerCase().trim()) >= 0);
};


export const LabelsOnStock = (props: { labels: Label[], isin: string, allLabels: string[] }) => {

    const [inputValue, setInputValue] = React.useState("");
    const [suggestions, setSuggestions] = React.useState<string[]>([]);
    const [labels, setLabels] = React.useState<Label[]>(props.labels);

    const [updateLabel] = useMutation(UPDATE_LABEL);
    const [deleteLabelOnStock] = useMutation(DELETE_LABEL_ON_STOCK);

    const inputProps: InputProps<string> = {
        placeholder: "key:value",
        value: inputValue,
        onChange: (e: React.FormEvent<HTMLElement>, params: ChangeEvent) => setInputValue(params.newValue),
        onKeyDown: addLabel
    };

    const onSuggestionsFetchRequested: SuggestionsFetchRequested = (request: SuggestionsFetchRequestedParams): void => {
        setSuggestions(getSuggestions(request.value, props.allLabels));
    };


    function addLabel(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            const currentValue: string = e.currentTarget.value;
            if (currentValue.indexOf(":") > 0) {
                const [key, value] = currentValue.split(":", 2);
                const newLabels = [...labels, {id: uuid(), key, value}];
                setLabels(newLabels);
                setInputValue("");

                updateLabel({
                    variables: {
                        labelForStock: {
                            isin: props.isin,
                            labels: newLabels.map(({id, ...keepAttributes}) => keepAttributes)
                        }
                    }
                })
                    .then(() => console.log("labels updated successfully"))
                    .catch(reason => console.log(`Error while updating labels: ${reason}`))
            }
        }
    }

    const removeLabel = (label: Label) => {
        console.log(label);
        deleteLabelOnStock({
            variables: {
                labelId: label.id,
                isin: props.isin,
            }
        })
            .then(() => {
                console.log("label removed");
                setLabels(labels.filter(l => l.id !== label.id));
            })
            .catch(reason => console.log(`Error while updating labels: ${reason}`));
    };

    const onSuggestionsClearRequested = () => setSuggestions([]);

    return <div className="LabelsBox">
        {labels?.map(l => <div className="Label" key={l.id}>{l.key}:{l.value} <span className="RemoveLabel"
                                                                                    onClick={() => removeLabel(l)}> <FontAwesomeIcon icon={faTrashAlt} /></span>
        </div>)}
        <Autosuggest suggestions={suggestions} inputProps={inputProps} getSuggestionValue={s => s}
                     onSuggestionsFetchRequested={onSuggestionsFetchRequested} onSuggestionsClearRequested={onSuggestionsClearRequested} renderSuggestion={renderSuggestion}/>
    </div>;
};
