import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {TransactionFormProps} from "../shared";
import {AzureAccount, AzureStockDto, AzureTransaction, TransactionType} from "../../shared/Domain";
import {AzureBackend} from "../../shared/AzureBackend";

export const TransactionForm: React.FC<TransactionFormProps> = ({transaction, accounts, stocks, onSave}) => {
    const [formData, setFormData] = useState<AzureTransaction>({
        id: transaction?.id || "",
        accountId: transaction?.accountId || "",
        isin: transaction?.isin || "",
        date: transaction?.date || new Date(),
        price: transaction?.price || 0,
        quantity: transaction?.quantity || 0,
        totalCosts: transaction?.totalCosts || 0,
        type: transaction?.type || TransactionType.BUY,
    });

    const [price, setPrice] = useState<string>(transaction ? transaction.price.toString() : "");
    const [quantity, setQuantity] = useState<string>(transaction ? transaction.quantity.toString() : "");
    const [totalCosts, setTotalCosts] = useState<string>(transaction ? transaction.totalCosts.toString() : "");

    const [stockSuggestions, setStockSuggestions] = useState<AzureStockDto[]>([]);
    const [stockName, setStockName] = useState<string>(transaction ? getStockName(transaction.isin) : "");

    function getStockName(isin: string) {
        return stocks.find(stock => stock.isin === isin)?.name || "";
    }

    const [accountSuggestions, setAccountSuggestions] = useState<AzureAccount[]>([]);
    const [accountName, setAccountName] = useState<string>(transaction ? getAccountName(transaction.accountId) : "");

    function getAccountName(id: string) {
        return accounts.find(a => a.id === id)?.name || "";
    }

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                setStockSuggestions([]);
                setAccountSuggestions([]);
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        if (name === "stockName") {
            setStockName(value);
            setStockSuggestions(stocks.filter(stock => stock.name.toLowerCase().includes(value.toLowerCase())));
        } else if (name === "accountName") {
            setAccountName(value);
            setAccountSuggestions(accounts.filter(account => account.name.toLowerCase().includes(value.toLowerCase())));
        } else if (name === "date") {
            setFormData({
                ...formData,
                date: new Date(value),
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        if (name === "price") {
            setPrice(value);
        } else if (name === "quantity") {
            setQuantity(value);
        } else {
            setTotalCosts(value);
        }
    };

    const handleStockSelect = (stock: AzureStockDto) => {
        setStockName(stock.name);
        setFormData({
            ...formData,
            isin: stock.isin,
        });
        setStockSuggestions([]);
    };

    const handleAccountSelect = (account: AzureAccount) => {
        setAccountName(account.name);
        setFormData({
            ...formData,
            accountId: account.id,
        });
        setAccountSuggestions([]);
    };

    const handleTransactionTypeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        setFormData({
            ...formData,
            type: e.target.value,
        });
    };

    const handleSubmit = (e: FormEvent) => {

        // use price, quantity, totalCosts and set them in formData
        formData.price = parseFloat(price.replace(",", "."));
        formData.quantity = parseFloat(quantity.replace(",", "."));
        formData.totalCosts = parseFloat(totalCosts.replace(",", "."));

        e.preventDefault();

        new AzureBackend().saveTransaction(formData, transaction)
            .then(r => {
                onSave(r);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const autoSuggestStyle = {
        listStyleType: "none",
        padding: 0,
        margin: 0,
        border: "1px solid #ccc",
    };

    const autoSuggestContainer = {
        backgroundColor: "white",
        border: "1px solid #ccc",
        color: "black",
        zIndex: 1000,
    };

    const transactionForm = {
        padding: "1em",
    };

    const labelStyle = {
        display: "inline-block",
        width: "10em",
    };

    const inputStyle = {
        width: "20em",
    };

    const inputGroupStyle = {
        marginBottom: "1em",
    };
    return (
        <form style={transactionForm} onSubmit={handleSubmit}>
            <div style={inputGroupStyle}>
                <label style={labelStyle}>Account Name:</label>
                <input
                    style={inputStyle}
                    type="text"
                    name="accountName"
                    value={accountName}
                    onChange={handleChange}
                    required
                />
                {accountSuggestions.length > 0 && (
                    <div style={{...autoSuggestContainer, position: "absolute"}}>
                        <ul style={autoSuggestStyle}>
                            {accountSuggestions.map(account => (
                                <li key={account.id} onClick={() => handleAccountSelect(account)}>
                                    {account.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <div style={inputGroupStyle}>
                <label style={labelStyle}>Stock Name:</label>
                <input
                    style={inputStyle}
                    type="text"
                    name="stockName"
                    value={stockName}
                    onChange={handleChange}
                    required
                />
                {stockSuggestions.length > 0 && (
                    <div style={{...autoSuggestContainer, position: "absolute"}}>
                        <ul style={autoSuggestStyle}>
                            {stockSuggestions.map(stock => (
                                <li key={stock.isin} onClick={() => handleStockSelect(stock)}>
                                    {stock.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <div style={inputGroupStyle}>
                <label style={labelStyle}>Date:</label>
                <input
                    style={inputStyle}
                    type="date"
                    name="date"
                    value={formData.date.toISOString().split('T')[0]}
                    onChange={handleChange}
                    required
                />
            </div>
            <div style={inputGroupStyle}>
                <label style={labelStyle}>Single Price (Euro):</label>
                <input
                    style={inputStyle}
                    type="text"
                    name="price"
                    value={price}
                    onChange={handleNumberChange}
                    required
                />
            </div>
            <div style={inputGroupStyle}>
                <label style={labelStyle}>Quantity (BigDecimal):</label>
                <input
                    style={inputStyle}
                    type="text"
                    name="quantity"
                    value={quantity}
                    onChange={handleNumberChange}
                    required
                />
            </div>
            <div style={inputGroupStyle}>
                <label style={labelStyle}>Total Costs (Euro):</label>
                <input
                    style={inputStyle}
                    type="text"
                    name="totalCosts"
                    value={totalCosts}
                    onChange={handleNumberChange}
                    required
                />
            </div>
            <div style={inputGroupStyle}>
                <label style={labelStyle}>Transaction Type:</label>
                <select
                    style={inputStyle}
                    name="type"
                    value={formData.type}
                    onChange={handleTransactionTypeSelect}
                    required
                >
                    <option value={TransactionType.SELL}>{TransactionType.SELL}</option>
                    <option value={TransactionType.BUY}>{TransactionType.BUY}</option>
                    <option value={TransactionType.DIVIDEND}>{TransactionType.DIVIDEND}</option>
                    <option value={TransactionType.TAXES}>{TransactionType.TAXES}</option>
                </select>
            </div>
            <button type="submit">Save Transaction</button>
        </form>
    );
};