import React, {ChangeEvent} from "react";
import {Spinner} from "./shared/components/Spinner";
import {gql, useQuery} from "@apollo/client";
import {numberFormat} from "./shared";
import {InvestmentsList} from "./shared/components/InvestmentsList";

const PORTFOLIO_QUERY = gql`
    query portfolio ($atDate: Date) {
        portfolio(at: $atDate) {
            cash
            stocksValue
            amountInvested
            timestamp
            investments {isin name price numberOfShares buyInTotal buyInPerShare transactions{costs}}
        }
    }
`;

export const PortfolioHistoryByDate = () => {

    const [date, setDate] = React.useState<Date>(new Date());

    const {loading, error, data} = useQuery(PORTFOLIO_QUERY, {
        variables: {atDate: date}, pollInterval: 0
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    function onChangeDate(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        console.log(value);
        if (value.length === 8) {
            const dd = value.slice(0, 2);
            const MM = value.slice(2, 4);
            const yyyy = value.slice(4, 8);
            setDate(new Date(+yyyy, +MM - 1, +dd));
        }
    }

    return (
        <Spinner isLoading={false}>
            <input type="string" onChange={onChangeDate}/>

            <div>Date: {date.toLocaleDateString("de-DE")}</div>
            <div>Cash: {numberFormat.format(data.portfolio.cash)}</div>
            <div>Value: {numberFormat.format(data.portfolio.stocksValue)}</div>
            <div>amountInvested: {numberFormat.format(data.portfolio.amountInvested)}</div>
            <div>Total: {numberFormat.format(data.portfolio.stocksValue + data.portfolio.cash)}</div>
            <InvestmentsList investments={data.portfolio.investments}/>
        </Spinner>
    );
}
