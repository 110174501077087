// Define a class that repesents the rest backend of the application.
// The class has a method that fetches the portfolio data from the backend.

import {AzureAccount, AzurePortfolio, AzurePortfolioHistory, AzureStockDto, AzureTransaction} from "./Domain";

export class AzureBackend {

    private static host = 'https://stockmonitor-backend-cbaqbqdagebxcfb4.germanywestcentral-01.azurewebsites.net';
    // private static host = 'http://localhost:8080';
    private basicAuth = 'Basic ' + btoa(localStorage.getItem('azure_user') + ":" + localStorage.getItem('azure_password'));

    async fetchPortfolioData(): Promise<AzurePortfolio> {
        let response = await fetch(AzureBackend.host + "/portfolio/total", {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json();
    }

    async fetchPortfolioByAccountId(accountId: string): Promise<AzurePortfolio> {
        let response = await fetch(`${AzureBackend.host}/portfolio/account?accountId=${accountId}`, {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json();
    }

    async fetchAllAccounts(): Promise<AzureAccount[]> {
        const response = await fetch(AzureBackend.host + "/accounts", {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json()
    }

    async fetchAllTransactions(): Promise<AzureTransaction[]> {
        const response = await fetch(AzureBackend.host + "/accounts/transactions", {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        const transactions: AzureTransaction[] = await response.json()
        return transactions.map(transaction => ({
            ...transaction,
            date: new Date(transaction.date)
        }));
    }

    async deleteTransaction(transactionId: string): Promise<void> {
        await fetch(`${AzureBackend.host}/accounts/transactions?transactionId=${transactionId}`, {
            method: 'DELETE',
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
    }

    async fetchAllStocks(): Promise<AzureStockDto[]> {
        const response = await fetch(AzureBackend.host + "/stocks", {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json();
    }

    async saveTransaction(transactionData: AzureTransaction, existingTransaction: AzureTransaction | undefined): Promise<AzureTransaction> {
        const method = existingTransaction ? "PUT" : "POST";
        const url = `${AzureBackend.host}/accounts/transactions`;

        const response = await fetch(url, {
            method,
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...transactionData,
                date: transactionData.date.toISOString().split('T')[0]
            }),
        });

        const transaction = await response.json();
        transaction.date = new Date(transaction.date);
        return transaction;
    }


    async fetchPortfolioHistory(from: Date, to: Date): Promise<AzurePortfolioHistory> {
        const response = await fetch(`${AzureBackend.host}/portfolio/history?from=${(from.toISOString())}&to=${(to.toISOString())}`, {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json();
    }
}