import React from "react";
import {TransactionList} from "../TransactionList";


export function TransactionsAzure() {
    return (
        <div>
            <h1>Transactions Azure</h1>
            <TransactionList/>
        </div>
    );
}

