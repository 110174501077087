import React, {useEffect} from "react";
import {Investment} from "../Domain";
import {Spinner} from "./Spinner";
import {gql, useQuery} from "@apollo/client";
import {InvestmentsList} from "./InvestmentsList";
import {v4 as uuid} from "uuid";

const PORTFOLIO = gql`
    query portfolio {
        portfolio {
            investments {isin name price numberOfShares buyInTotal buyInPerShare transactions{id size price timestamp costs transactionType}}
        }
    }
`;

export const StocksInPortfolio = () => {

    const [investments, setInvestments] = React.useState<Array<Investment>>([]);
    const {error, data} = useQuery(PORTFOLIO, {pollInterval: 60000});
    const [keyIdToTriggerAnimation, setKeyIdToTriggerAnimation] = React.useState<string>(uuid());

    useEffect(() => {
        if (data) {
            setInvestments(data.portfolio.investments);
            setKeyIdToTriggerAnimation(uuid());
        }
    }, [data]);

    if (error) return <p>Error :(</p>;

    return (
        <Spinner isLoading={investments?.length === 0}>
            <div key={keyIdToTriggerAnimation} className="Widget greenBackgroundFadeOut">
                <InvestmentsList investments={investments}/>
            </div>
        </Spinner>
    );
}
