import React from 'react';

export const TokenInput = () => {

    const handleTokenChange = (e: React.FormEvent<HTMLInputElement>) => {
        localStorage.setItem("token", e.currentTarget.value);
    };

    return (
        <div className="Widget">
            <h1>Token Input</h1>
            <label>
                Token:
                <input type="text" name="token" onChange={handleTokenChange}/>
            </label>
        </div>
    )
}
