import React, {useEffect} from "react";
import {Chart} from "react-google-charts";
import {AzureBackend} from "../../shared/AzureBackend";

export const StockPortionPieChart = () => {

    const [chartData, setChartData] = React.useState<any[][]>([["Name", "Amount"]]);

    useEffect(() => {
        new AzureBackend().fetchPortfolioData()
            .then(portfolio => {
                    new AzureBackend().fetchAllStocks()
                        .then(stocks => {
                            const mappedChartData: any[][] = [
                                ["Name", "Amount"],
                            ];
                            const sortedInvestments = portfolio.investments;
                            sortedInvestments.sort((a, b) => a.currentValueInEuro - b.currentValueInEuro);

                            sortedInvestments.reduce((acc, i) => {
                                mappedChartData.push([stocks.find(s => s.isin === i.isin)?.name || i.isin, i.currentValueInEuro])
                                return mappedChartData;
                            }, mappedChartData);
                            setChartData(mappedChartData);
                        });
                }
            );
    }, []);

    return (<div className="Widget">

        {chartData.length > 1 ? (
            <Chart
                height={'400px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={chartData}
                options={{
                    backgroundColor: 'black',
                    chartArea: {width: '90%', height: '90%'},
                    titleTextStyle: {
                        color: 'white',
                    },
                    legendTextStyle: {
                        color: 'white',
                    },
                    pieHole: 0.7,
                }}
                rootProps={{'data-testid': '3'}}
            />
        ) : <div>Loading...</div>}
    </div>)
};
