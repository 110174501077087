import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Label} from "./shared/Domain";
import {LabelComponent} from "./LabelComponent";

const QUERY_LABELS = gql`
    query labels{
        labels{
            id key value
        }
    }
`;

export function Labels() {

    const [currentLabel, setCurrentLabel] = React.useState<{ id: string, name: string }>({id: "", name: ""});
    const {data, loading, error} = useQuery(QUERY_LABELS);

    if (error) return (<p>Error :(</p>)
    if (loading) return (<p>Loading...</p>)

    const sortedLabels = data.labels.slice().sort((l: Label, r: Label) => (l.key + l.value).localeCompare((r.key + r.value)))

    return <div>
        <h1>Labels</h1>
        <div>
            {sortedLabels.map((l: Label) => <button key={l.id}
                                                    onClick={() => setCurrentLabel({
                                                        id: l.id,
                                                        name: `${l.key}:${l.value}`
                                                    })}>{l.key}:{l.value}</button>)}
        </div>
        {currentLabel.id.length > 0 && <LabelComponent id={currentLabel.id} name={currentLabel.name}/>}
    </div>;
}
