import React, {useEffect} from "react";
import {getLast24Hours} from "../shared";
import {gql, useQuery} from "@apollo/client";
import {GraphQLResponse, StockHistory} from "../shared/Domain";
import "./StockTrendLineChart.css";
import {HistoryChart} from "./HistoryChart";

const QUERY = gql`
    query stockprices ($isin: String, $from: Date $to: Date){
        stockHistory( isin: $isin, from: $from, to: $to){
            date
            isin
            name
            price
        }
    }
`;

export const StockTrendLineChart = (props: { isin: string }) => {

    const [to, setTo] = React.useState<Date>(new Date());
    const [from, setFrom] = React.useState<Date>(getLast24Hours());
    const [chartData, setChartData] = React.useState(new Array<Array<any>>());
    const [name, setName] = React.useState("");

    const {error, data, refetch} = useQuery(QUERY, {
        variables: {
            isin: props.isin,
            from: from,
            to: to
        },
    });

    useEffect(() => {
        const mapToChartData = (data: GraphQLResponse) => {
            const stockHistoryEntries: [StockHistory] = data.stockHistory;
            const mappedData: Array<Array<any>> = stockHistoryEntries.map((sh: StockHistory) => {
                return [new Date(sh.date), sh.price];
            });
            return mappedData;
        };
        if (data) {
            if (data.stockHistory.length === 0) {
                setChartData([["0", "Value"]]);
            } else {
                setName(data.stockHistory[0].name);
                setChartData([["0", "Value"]].concat(mapToChartData(data)));
            }
        }
        const interval = setInterval(() => {
            setTo(new Date())
        }, 60000);
        return () => clearInterval(interval)
    }, [data]);

    if (error) return <p>Error :(</p>;

    const changeTimeframe = (hoursBack: number) => {
        const newFromDate = new Date(to);
        newFromDate.setHours(newFromDate.getHours() - hoursBack)
        setFrom(newFromDate);
        refetch().then(() => console.log("refetched successfully"));
    };

    return <HistoryChart chartData={chartData} isin={props.isin} name={name} changeTimeWindow={changeTimeframe}/>;
};
