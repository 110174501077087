import React, {useEffect} from "react";
import {gql, useQuery} from "@apollo/client";
import {Transaction, TransactionType} from "./shared/Domain";
import {numberFormat} from "./shared";
import "./TransactionWithProfitLoss.css"
import {Link} from "react-router-dom";

const QUERY = gql`
    query allTransactions {
        transactions {
            id
            name
            timestamp
            isin
            sum
            costs
            transactionType
            profitLoss {
                sum
            }
        }
    }`;

type TransactionWithProfitLoss = Transaction & { profitLoss: number };

export const ProfitLossOverview = (props: { year: number }) => {


    const [transactionsWithProfitLoss, setTransactionsWithProfitLoss] = React.useState<TransactionWithProfitLoss[]>([]);
    const [profitLoss, setRealizedProfitLoss] = React.useState(0);
    const {data, loading, error} = useQuery(QUERY);

    useEffect(() => {
        if (data) {
            const transactionsThisYear = props.year === 0
                ? data.transactions.filter((t: Transaction) => t.transactionType !== "BUY")
                : data.transactions.filter((t: Transaction) => new Date(t.timestamp).getFullYear() === props.year && t.transactionType !== "BUY");
            let realizedProfitLoss = 0;
            const transactionWithPL = transactionsThisYear.map((tty: Transaction) => {
                let profitLoss: number = 0;
                if (tty.transactionType === TransactionType.DIVIDEND || tty.transactionType === TransactionType.TAXES) {
                    profitLoss = tty.sum;
                } else if (tty.transactionType === TransactionType.SELL && tty.profitLoss) {
                    profitLoss = tty.profitLoss.sum;
                }
                realizedProfitLoss += profitLoss;
                return {
                    ...tty,
                    profitLoss: profitLoss
                };
            });
            setRealizedProfitLoss(realizedProfitLoss);
            setTransactionsWithProfitLoss(transactionWithPL);
        }
    }, [data, props.year]);

    if (loading) return <span>Loading...</span>;
    if (error) return <span>Error :-(</span>;

    return (
        <React.Fragment>
            <table>
                {transactionsWithProfitLoss.map((t) => {
                    return (
                        <tbody key={t.id}>
                        <tr className="transaction-row">
                            <td>{new Date(t.timestamp).toLocaleDateString("de-DE")}</td>
                            <td><Link to={{pathname: `/stock/${t.isin}`}}>{t.isin}</Link></td>
                            <td>{t.name}</td>
                            <td>{numberFormat.format(t.sum)}</td>
                            <td>{t.transactionType}</td>
                            <td className={t.profitLoss > 0 ? "changePositive" : "changeNegative"}>
                                {numberFormat.format(t.profitLoss)}</td>
                        </tr>
                        </tbody>)
                })}
            </table>
            <div><span
                className={profitLoss > 0 ? "changePositive" : "changeNegative"}>P/L: {numberFormat.format(profitLoss)}</span>
            </div>
        </React.Fragment>
    );
};
