import React, {useEffect} from "react";
import {AzureStockDto} from "../../shared/Domain";
import {AzureStock} from "../shared";
import {AzureBackend} from "../../shared/AzureBackend";
import {Spinner} from "../../shared/components/Spinner";
import {AzureStockPriceTable} from "./AzureStockPriceTable";


export function AzureStockChangeOnDailyBase() {

    const [stocks, setStocks] = React.useState<AzureStock[]>([]);

    useEffect(() => {
        const azureBackend = new AzureBackend();
        const fetchAllStocks = azureBackend.fetchAllStocks();
        const fetchPortfolioData = azureBackend.fetchPortfolioData();
        Promise.all([fetchAllStocks, fetchPortfolioData]).then(([stocks, portfolio]) => {
            const investedIsins = portfolio.investments.flatMap(i => i.isin);
            setStocks(stocks
                .filter(s => investedIsins.includes(s.isin))
                .map((s: AzureStockDto) => AzureStock.fromDto(s)));
        });
    }, [])

    return <div>
        <Spinner isLoading={stocks.length === 0}>
            <div className="Widget"><AzureStockPriceTable stocks={stocks}/></div>
        </Spinner>
    </div>;
}
