import {AzureAccount, AzureStockDto, AzureTransaction} from "../shared/Domain";

export interface TransactionFormProps {
    transaction?: AzureTransaction;
    accounts: AzureAccount[];
    stocks: AzureStockDto[];
    onSave: (transaction: AzureTransaction) => void;
}

export class AzureStock {
    constructor(public isin: string, public name: string, public currentPrice: number, public priceDayBefore: number) {
    }

    static fromDto(dto: AzureStockDto): AzureStock {
        return new AzureStock(dto.isin, dto.name, dto.priceInEuro, dto.priceInEuroDayBefore);
    }

    changeAbsolute(): number {
        return this.currentPrice - this.priceDayBefore;
    }

    changeRelative(): number {
        return this.changeAbsolute() / this.priceDayBefore * 100;
    }
}
