import React, {useEffect} from "react";
import {AzurePortfolio} from "../../shared/Domain";
import {numberFormat} from "../../shared";
import {AzureBackend} from "../../shared/AzureBackend";


export function AzurePortfolioOverview() {

    const [azurePortfolio, setAzurePortfolio] = React.useState<AzurePortfolio>({
        currentProfitLossInEuro: 0,
        currentValueOfStocksInEuro: 0,
        dividendSumInEuro: 0,
        sellSumInEuro: 0,
        buySumInEuro: 0,
        taxesPayedInEuro: 0,
        investedSumInEuro: 0,
        investments: []
    });
    const [changeAbsolute, setChangeAbsolute] = React.useState<number>(0);
    const [changeInPercentage, setChangeInPercentage] = React.useState<number>(0);

    useEffect(() => {
        const azureBackend = new AzureBackend();

        azureBackend.fetchPortfolioData().then(data => {
            setAzurePortfolio(data);
            setChangeAbsolute(data.currentValueOfStocksInEuro - data.investedSumInEuro);
            setChangeInPercentage((data.currentValueOfStocksInEuro - data.investedSumInEuro) / data.investedSumInEuro);
        });

    }, []);

    if (localStorage.getItem('azure_user') === null || localStorage.getItem('azure_password') === null) {
        return (
            <div className="Widget">
                <h1>Azure Portfolio Overview</h1>
                <p>Please configure your Azure credentials in the configuration section.</p>
            </div>
        );
    }


    return (
        <React.Fragment>
            <div className="Widget portfolioValues">
                <div>Current value of stocks:</div>
                <div>{numberFormat.format(azurePortfolio.currentValueOfStocksInEuro)}</div>

                <div>Sell sum:</div>
                <div>{numberFormat.format(azurePortfolio.sellSumInEuro)}</div>

                <div>Buy sum:</div>
                <div>{numberFormat.format(azurePortfolio.buySumInEuro)}</div>

                <div>Dividend sum:</div>
                <div>{numberFormat.format(azurePortfolio.dividendSumInEuro)}</div>

                <div>Taxes payed:</div>
                <div>{numberFormat.format(azurePortfolio.taxesPayedInEuro)}</div>

                <div>Current P/R:</div>
                <div
                    className="green topBorder">{numberFormat.format(azurePortfolio.currentProfitLossInEuro)}
                </div>

                <div className="topBuffer">Invested sum:</div>
                <div>{numberFormat.format(azurePortfolio.investedSumInEuro)}</div>

                <div className={changeAbsolute > 0 ? "green larger" : "changeNegative"}>Change:</div>
                <div className={changeAbsolute > 0 ? "green larger" : "changeNegative"}>
                    {changeAbsolute > 0 ? "↗" : "↘"} {numberFormat.format(changeAbsolute)}
                </div>
                <div className="topBuffer">Change in %:</div>
                <div>{(changeInPercentage * 100).toFixed(2)} %</div>
            </div>
        </React.Fragment>
    );
}