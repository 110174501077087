import React, {useEffect} from "react";
import {numberFormat} from "../../shared";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSort} from "@fortawesome/free-solid-svg-icons";
import {AzureStock} from "../shared";

export const AzureStockPriceTable = (props: { stocks: AzureStock[] }) => {

    const [sortedStocks, setSortedStocks] = React.useState<AzureStock[]>([]);


    useEffect(() => {
        setSortedStocks(props.stocks.slice().sort(sortByChange()));
    }, [props.stocks]);

    const sortByName = () => (l: AzureStock, r: AzureStock) => l.name.localeCompare(r.name);
    const sortByChange = () => (l: AzureStock, r: AzureStock) => (r.changeRelative() - l.changeRelative());
    const setStocksSortedByName = () => setSortedStocks(props.stocks.slice().sort(sortByName()));
    const setStocksSortedByChange = () => setSortedStocks(props.stocks.slice().sort(sortByChange()));

    return <table>
        <thead>
        <tr>
            <td className="ColumnWithSpace" onClick={setStocksSortedByName}>Name <FontAwesomeIcon icon={faSort}/></td>
            <td className="ColumnWithSpace">Price</td>
            <td className="ColumnWithSpace" onClick={setStocksSortedByChange}>Change <FontAwesomeIcon icon={faSort}/>
            </td>
        </tr>
        </thead>
        <tbody>{sortedStocks.map((s: AzureStock) =>
            <tr className="HoverRow" key={s.isin}>
                <td className="ColumnWithSpace">{s.name}</td>
                <td className="ColumnWithSpace">{numberFormat.format(s.currentPrice)}</td>
                <td
                    className={(s.changeAbsolute() > 0 ? " changePositive" : "changeNegative") + " ColumnWithSpace"}> {s.changeRelative().toFixed(2)} %
                </td>
            </tr>)}
        </tbody>
    </table>
}
