import React, {useEffect} from "react";
import {Account, Transaction} from "../Domain";
import "./TransactionsList.css";
import {gql, useMutation, useQuery} from "@apollo/client";
import {formatDate, numberFormat} from "../../shared";

const TRANSACTIONS = gql`
    query allTransactions {
        transactions{
            id
            timestamp
            name
            isin
            sum
            costs
            price
            size
            transactionType
            account {
                id
                name
            }
        }
        accounts {
            id
            name
        }
    }
`;

const ASSIGN_TRANSACTION_TO_ACCOUNT = gql`
    mutation assignTransactionToAccount($transactionId: String!, $accountId: String!) {
        assignTransactionToAccount(transactionId: $transactionId, accountId: $accountId) {
            id
            name
        }
    }
`;


export const TransactionsList = () => {

    const {data} = useQuery(TRANSACTIONS);
    const [transactions, setTransactions] = React.useState<Array<Transaction>>([]);
    const [accounts, setAccounts] = React.useState<Array<Account>>([]);
    const [assignTransactionToAccount] = useMutation(ASSIGN_TRANSACTION_TO_ACCOUNT);

    useEffect(() => {
        if (data) {
            setTransactions(
                data.transactions.slice()
                    .sort((a: Transaction, b: Transaction) => {
                        return new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf();
                    })
            );
            setAccounts(data.accounts);
        }
    }, [data]);

    function assignAccount(transactionId: string, accountId: string) {
        console.log(`Assigning transaction ${transactionId} to account ${accountId}`);
        assignTransactionToAccount({
            variables: {
                transactionId,
                accountId
            }
        }).then(() => {
            setTransactions(transactions.map(t => {
                if (t.id === transactionId) {
                    return {
                        ...t,
                        account: accounts.find(a => a.id === accountId)
                    }
                }
                return t;
            }));
        });
    }

    return (
        <div className="Widget">
            <h1>transactions</h1>
            <div className="TransactionList">{transactions.map(t => (
                <div className="TransactionListEntry" key={t.id}>
                    <div className="TransactionListEntryHeader">
                        <div>{formatDate(t.timestamp)}</div>
                        <div>{t.name}</div>
                        <div>{numberFormat.format(t.sum)}</div>
                    </div>
                    <div className="TransactionListEntrySub">
                        <div>
                            selected account: {t.account? t.account.name : "none"} &nbsp;
                            <select defaultValue={t.account?.id} onChange={e => assignAccount(t.id, e.target.value)}>
                                {accounts.map(a => (
                                    <option key={a.id}
                                            value={a.id}>{a.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>Value: ({numberFormat.format(t.price)} * {t.size})</div>
                        <div>Costs {numberFormat.format(t.costs)}</div>
                        <div>{t.transactionType}</div>
                    </div>
                </div>
            ))}
            </div>
        </div>
    )
}
