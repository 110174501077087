import React, {useEffect} from "react";
import {gql, useQuery} from "@apollo/client";
import {Stock, Transaction} from "./shared/Domain";
import {StockTrendLineChart} from "./dashboard/StockTrendLineChart";
import {formatDate, numberFormat as nf, numberFormat} from "./shared";


const QUERY_STOCK = gql`
    query stocks($isin: String!) {
        stock(isin: $isin){
            id, isin, name, showChartOnDashboard, collectPrices, 
            price { current }
            stockTransactions{
                sumBuy sumDividend sumSell sumTaxes
            }
        }
        transactions(isin: $isin){
            id
            timestamp
            name
            isin
            sum
            costs
            price
            size
            transactionType
        }
    }
`;


export const StockInfo = (props: any) => {

    const [stock, setStock] = React.useState<Stock>();
    const [transactions, setTransactions] = React.useState<Transaction[]>([]);
    const {data, loading, error} = useQuery(QUERY_STOCK, {
        variables: {isin: props.match.params.isin},
    });
    const [quantity, setQuantity] = React.useState(0);
    const [totalSum, setTotalSum] = React.useState(0);

    useEffect(() => {
        if (data) {
            setStock(data.stock);
            setTransactions(
                data.transactions.slice()
                    .sort((a: Transaction, b: Transaction) => {
                        return new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf();
                    })
            );
            const currentQuantity = data.transactions.filter((t: Transaction) => t.transactionType === "BUY").reduce((a: number, b: Transaction) => a + b.size, 0)
                - data.transactions.filter((t: Transaction) => t.transactionType === "SELL").reduce((a: number, b: Transaction) => a + b.size, 0);
            setQuantity(currentQuantity);
            setTotalSum((data.stock.stockTransactions.sumSell + data.stock.stockTransactions.sumDividend + (currentQuantity * data.stock.price.current))
                - (data.stock.stockTransactions.sumBuy + data.stock.stockTransactions.sumTaxes));
        }
    }, [data]);

    if (error)  console.log(error);

    if (loading) return (<p>Loading :(</p>)


    return <React.Fragment>
        <h1>{stock?.name}</h1>
        {error && <p>{error.message}</p>}
        <div className="Widget">
            <div>current price: {nf.format(data.stock?.price.current)}</div>
            <div>quantity: {quantity}</div>
            <div>sum buy: {nf.format(data.stock.stockTransactions.sumBuy)}</div>
            <div>sum sell: {nf.format(data.stock.stockTransactions.sumSell)}</div>
            <div>sum dividend: {nf.format(data.stock.stockTransactions.sumDividend)}</div>
            <div>sum taxes: {nf.format(data.stock.stockTransactions.sumTaxes)}</div>

            <div className={totalSum > 0 ? " changePositive" : "changeNegative"}>Total sum (current value included):
                {nf.format(totalSum)}
            </div>
        </div>
        {stock?.isin && <StockTrendLineChart isin={stock?.isin}/>}
        {transactions.length > 0 && <h2>My Transactions</h2>}
        <ul>{transactions.map(t => (
            <li key={t.id}>{formatDate(t.timestamp)}: {t.name} {numberFormat.format(t.sum)} -
                ({numberFormat.format(t.price)} * {t.size}) - {numberFormat.format(t.costs)} - {t.transactionType}</li>
        ))}</ul>
    </React.Fragment>
};

